export const OneTrust = () => import('../../components/OneTrust.vue' /* webpackChunkName: "components/one-trust" */).then(c => wrapFunctional(c.default || c))
export const Aggregator = () => import('../../components/Aggregator/Aggregator.vue' /* webpackChunkName: "components/aggregator" */).then(c => wrapFunctional(c.default || c))
export const Filters = () => import('../../components/Aggregator/Filters.vue' /* webpackChunkName: "components/filters" */).then(c => wrapFunctional(c.default || c))
export const HomeFilters = () => import('../../components/Aggregator/HomeFilters.vue' /* webpackChunkName: "components/home-filters" */).then(c => wrapFunctional(c.default || c))
export const BroadcasterImage = () => import('../../components/Broadcaster/BroadcasterImage.vue' /* webpackChunkName: "components/broadcaster-image" */).then(c => wrapFunctional(c.default || c))
export const CarouselNavigation = () => import('../../components/Carousel/CarouselNavigation.vue' /* webpackChunkName: "components/carousel-navigation" */).then(c => wrapFunctional(c.default || c))
export const ConditionalLink = () => import('../../components/ConditionalLink/conditionalLink.vue' /* webpackChunkName: "components/conditional-link" */).then(c => wrapFunctional(c.default || c))
export const ReturnLinkTag = () => import('../../components/ConditionalLink/returnLinkTag.js' /* webpackChunkName: "components/return-link-tag" */).then(c => wrapFunctional(c.default || c))
export const Aside = () => import('../../components/Content/Aside.vue' /* webpackChunkName: "components/aside" */).then(c => wrapFunctional(c.default || c))
export const Content = () => import('../../components/Content/Content.vue' /* webpackChunkName: "components/content" */).then(c => wrapFunctional(c.default || c))
export const ContentBlock = () => import('../../components/Content/ContentBlock.vue' /* webpackChunkName: "components/content-block" */).then(c => wrapFunctional(c.default || c))
export const FlexibleContent = () => import('../../components/Content/FlexibleContent.vue' /* webpackChunkName: "components/flexible-content" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/Content/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const ResponsiveImage = () => import('../../components/Content/ResponsiveImage.vue' /* webpackChunkName: "components/responsive-image" */).then(c => wrapFunctional(c.default || c))
export const Swipe = () => import('../../components/Content/Swipe.vue' /* webpackChunkName: "components/swipe" */).then(c => wrapFunctional(c.default || c))
export const AberDNABenefits = () => import('../../components/ContentBlocks/AberDNABenefits.vue' /* webpackChunkName: "components/aber-d-n-a-benefits" */).then(c => wrapFunctional(c.default || c))
export const ButtonsBlock = () => import('../../components/ContentBlocks/ButtonsBlock.vue' /* webpackChunkName: "components/buttons-block" */).then(c => wrapFunctional(c.default || c))
export const Extract = () => import('../../components/ContentBlocks/Extract.vue' /* webpackChunkName: "components/extract" */).then(c => wrapFunctional(c.default || c))
export const FlexibleBlockTitle = () => import('../../components/ContentBlocks/FlexibleBlockTitle.vue' /* webpackChunkName: "components/flexible-block-title" */).then(c => wrapFunctional(c.default || c))
export const IconsBlock = () => import('../../components/ContentBlocks/IconsBlock.vue' /* webpackChunkName: "components/icons-block" */).then(c => wrapFunctional(c.default || c))
export const KeyPoints = () => import('../../components/ContentBlocks/KeyPoints.vue' /* webpackChunkName: "components/key-points" */).then(c => wrapFunctional(c.default || c))
export const LinkText = () => import('../../components/ContentBlocks/LinkText.vue' /* webpackChunkName: "components/link-text" */).then(c => wrapFunctional(c.default || c))
export const NewsBlock = () => import('../../components/ContentBlocks/NewsBlock.vue' /* webpackChunkName: "components/news-block" */).then(c => wrapFunctional(c.default || c))
export const PaymentCard = () => import('../../components/ContentBlocks/PaymentCard.vue' /* webpackChunkName: "components/payment-card" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethods = () => import('../../components/ContentBlocks/PaymentMethods.vue' /* webpackChunkName: "components/payment-methods" */).then(c => wrapFunctional(c.default || c))
export const VideoBlock = () => import('../../components/ContentBlocks/VideoBlock.vue' /* webpackChunkName: "components/video-block" */).then(c => wrapFunctional(c.default || c))
export const CookieNotice = () => import('../../components/CookieNotice/CookieNotice.vue' /* webpackChunkName: "components/cookie-notice" */).then(c => wrapFunctional(c.default || c))
export const DFPHeader = () => import('../../components/DFP/DFPHeader.vue' /* webpackChunkName: "components/d-f-p-header" */).then(c => wrapFunctional(c.default || c))
export const DFPScript = () => import('../../components/DFP/DFPScript.vue' /* webpackChunkName: "components/d-f-p-script" */).then(c => wrapFunctional(c.default || c))
export const Document = () => import('../../components/Documents/Document.vue' /* webpackChunkName: "components/document" */).then(c => wrapFunctional(c.default || c))
export const DocumentList = () => import('../../components/Documents/DocumentList.vue' /* webpackChunkName: "components/document-list" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FooterBottom = () => import('../../components/Footer/FooterBottom.vue' /* webpackChunkName: "components/footer-bottom" */).then(c => wrapFunctional(c.default || c))
export const Gallery = () => import('../../components/Gallery/Gallery.vue' /* webpackChunkName: "components/gallery" */).then(c => wrapFunctional(c.default || c))
export const GalleryLightbox = () => import('../../components/Gallery/GalleryLightbox.vue' /* webpackChunkName: "components/gallery-lightbox" */).then(c => wrapFunctional(c.default || c))
export const Fixtures = () => import('../../components/Fixtures/Fixtures.vue' /* webpackChunkName: "components/fixtures" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/Header/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const TopHeader = () => import('../../components/Header/TopHeader.vue' /* webpackChunkName: "components/top-header" */).then(c => wrapFunctional(c.default || c))
export const HomeFlexibleContent = () => import('../../components/Home/HomeFlexibleContent.vue' /* webpackChunkName: "components/home-flexible-content" */).then(c => wrapFunctional(c.default || c))
export const DarkLoader = () => import('../../components/Loaders/DarkLoader.vue' /* webpackChunkName: "components/dark-loader" */).then(c => wrapFunctional(c.default || c))
export const LightLoader = () => import('../../components/Loaders/LightLoader.vue' /* webpackChunkName: "components/light-loader" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/Loaders/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const Map = () => import('../../components/Map/Map.vue' /* webpackChunkName: "components/map" */).then(c => wrapFunctional(c.default || c))
export const MatchCentre = () => import('../../components/MatchCentre/MatchCentre.vue' /* webpackChunkName: "components/match-centre" */).then(c => wrapFunctional(c.default || c))
export const MatchCentreContent = () => import('../../components/MatchCentre/MatchCentreContent.vue' /* webpackChunkName: "components/match-centre-content" */).then(c => wrapFunctional(c.default || c))
export const MatchCentreSidebar = () => import('../../components/MatchCentre/MatchCentreSidebar.vue' /* webpackChunkName: "components/match-centre-sidebar" */).then(c => wrapFunctional(c.default || c))
export const MatchCentreTabs = () => import('../../components/MatchCentre/MatchCentreTabs.vue' /* webpackChunkName: "components/match-centre-tabs" */).then(c => wrapFunctional(c.default || c))
export const ClubLogo = () => import('../../components/MatchComponents/ClubLogo.vue' /* webpackChunkName: "components/club-logo" */).then(c => wrapFunctional(c.default || c))
export const LeagueTableLarge = () => import('../../components/MatchComponents/LeagueTableLarge.vue' /* webpackChunkName: "components/league-table-large" */).then(c => wrapFunctional(c.default || c))
export const LeagueTableSmall = () => import('../../components/MatchComponents/LeagueTableSmall.vue' /* webpackChunkName: "components/league-table-small" */).then(c => wrapFunctional(c.default || c))
export const MatchCountdown = () => import('../../components/MatchComponents/MatchCountdown.vue' /* webpackChunkName: "components/match-countdown" */).then(c => wrapFunctional(c.default || c))
export const NextMatch = () => import('../../components/MatchComponents/NextMatch.vue' /* webpackChunkName: "components/next-match" */).then(c => wrapFunctional(c.default || c))
export const Heading = () => import('../../components/PageHeading/Heading.vue' /* webpackChunkName: "components/heading" */).then(c => wrapFunctional(c.default || c))
export const HeadingSponsor = () => import('../../components/PageHeading/HeadingSponsor.vue' /* webpackChunkName: "components/heading-sponsor" */).then(c => wrapFunctional(c.default || c))
export const HeadingSubmenu = () => import('../../components/PageHeading/HeadingSubmenu.vue' /* webpackChunkName: "components/heading-submenu" */).then(c => wrapFunctional(c.default || c))
export const SponsorItem = () => import('../../components/PageHeading/SponsorItem.vue' /* webpackChunkName: "components/sponsor-item" */).then(c => wrapFunctional(c.default || c))
export const IndexItem = () => import('../../components/PageIndex/IndexItem.vue' /* webpackChunkName: "components/index-item" */).then(c => wrapFunctional(c.default || c))
export const PageIndex = () => import('../../components/PageIndex/PageIndex.vue' /* webpackChunkName: "components/page-index" */).then(c => wrapFunctional(c.default || c))
export const PartnerIndex = () => import('../../components/PartnerIndex/PartnerIndex.vue' /* webpackChunkName: "components/partner-index" */).then(c => wrapFunctional(c.default || c))
export const PartnerIndexGroup = () => import('../../components/PartnerIndex/PartnerIndexGroup.vue' /* webpackChunkName: "components/partner-index-group" */).then(c => wrapFunctional(c.default || c))
export const Player = () => import('../../components/Player/Player.vue' /* webpackChunkName: "components/player" */).then(c => wrapFunctional(c.default || c))
export const PlayerHeader = () => import('../../components/Player/PlayerHeader.vue' /* webpackChunkName: "components/player-header" */).then(c => wrapFunctional(c.default || c))
export const PlayerInfo = () => import('../../components/Player/PlayerInfo.vue' /* webpackChunkName: "components/player-info" */).then(c => wrapFunctional(c.default || c))
export const PlayerStats = () => import('../../components/Player/PlayerStats.vue' /* webpackChunkName: "components/player-stats" */).then(c => wrapFunctional(c.default || c))
export const AssistSvg = () => import('../../components/SVG/AssistSvg.vue' /* webpackChunkName: "components/assist-svg" */).then(c => wrapFunctional(c.default || c))
export const GoalPostSvg = () => import('../../components/SVG/GoalPostSvg.vue' /* webpackChunkName: "components/goal-post-svg" */).then(c => wrapFunctional(c.default || c))
export const GoalSvg = () => import('../../components/SVG/GoalSvg.vue' /* webpackChunkName: "components/goal-svg" */).then(c => wrapFunctional(c.default || c))
export const HelixSvg = () => import('../../components/SVG/HelixSvg.vue' /* webpackChunkName: "components/helix-svg" */).then(c => wrapFunctional(c.default || c))
export const OwnGoalSvg = () => import('../../components/SVG/OwnGoalSvg.vue' /* webpackChunkName: "components/own-goal-svg" */).then(c => wrapFunctional(c.default || c))
export const PitchMobileSvg = () => import('../../components/SVG/PitchMobileSvg.vue' /* webpackChunkName: "components/pitch-mobile-svg" */).then(c => wrapFunctional(c.default || c))
export const PitchSvg = () => import('../../components/SVG/PitchSvg.vue' /* webpackChunkName: "components/pitch-svg" */).then(c => wrapFunctional(c.default || c))
export const FeaturedPlayer = () => import('../../components/Sidebar/FeaturedPlayer.vue' /* webpackChunkName: "components/featured-player" */).then(c => wrapFunctional(c.default || c))
export const Sidebar = () => import('../../components/Sidebar/Sidebar.vue' /* webpackChunkName: "components/sidebar" */).then(c => wrapFunctional(c.default || c))
export const SidebarMatchTabs = () => import('../../components/Sidebar/SidebarMatchTabs.vue' /* webpackChunkName: "components/sidebar-match-tabs" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../components/Slider/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const SliderNavigation = () => import('../../components/Slider/SliderNavigation.vue' /* webpackChunkName: "components/slider-navigation" */).then(c => wrapFunctional(c.default || c))
export const Social = () => import('../../components/Social/Social.vue' /* webpackChunkName: "components/social" */).then(c => wrapFunctional(c.default || c))
export const SocialShare = () => import('../../components/Social/SocialShare.vue' /* webpackChunkName: "components/social-share" */).then(c => wrapFunctional(c.default || c))
export const SoticSA = () => import('../../components/SoticSA/SoticSA.vue' /* webpackChunkName: "components/sotic-s-a" */).then(c => wrapFunctional(c.default || c))
export const SoticSAMenu = () => import('../../components/SoticSA/SoticSAMenu.vue' /* webpackChunkName: "components/sotic-s-a-menu" */).then(c => wrapFunctional(c.default || c))
export const PlayerCard = () => import('../../components/Squad/PlayerCard.vue' /* webpackChunkName: "components/player-card" */).then(c => wrapFunctional(c.default || c))
export const Squad = () => import('../../components/Squad/Squad.vue' /* webpackChunkName: "components/squad" */).then(c => wrapFunctional(c.default || c))
export const SquadFilter = () => import('../../components/Squad/SquadFilter.vue' /* webpackChunkName: "components/squad-filter" */).then(c => wrapFunctional(c.default || c))
export const SquadSelector = () => import('../../components/Squad/SquadSelector.vue' /* webpackChunkName: "components/squad-selector" */).then(c => wrapFunctional(c.default || c))
export const StaffCard = () => import('../../components/Staff/StaffCard.vue' /* webpackChunkName: "components/staff-card" */).then(c => wrapFunctional(c.default || c))
export const StaffIndex = () => import('../../components/Staff/StaffIndex.vue' /* webpackChunkName: "components/staff-index" */).then(c => wrapFunctional(c.default || c))
export const HomeStats = () => import('../../components/Stats/HomeStats.vue' /* webpackChunkName: "components/home-stats" */).then(c => wrapFunctional(c.default || c))
export const Stats = () => import('../../components/Stats/Stats.vue' /* webpackChunkName: "components/stats" */).then(c => wrapFunctional(c.default || c))
export const Video = () => import('../../components/Video/Video.vue' /* webpackChunkName: "components/video" */).then(c => wrapFunctional(c.default || c))
export const VideoList = () => import('../../components/Video/VideoList.vue' /* webpackChunkName: "components/video-list" */).then(c => wrapFunctional(c.default || c))
export const VideoSignUp = () => import('../../components/Video/VideoSignUp.vue' /* webpackChunkName: "components/video-sign-up" */).then(c => wrapFunctional(c.default || c))
export const NewsCard = () => import('../../components/Aggregator/Cards/NewsCard.vue' /* webpackChunkName: "components/news-card" */).then(c => wrapFunctional(c.default || c))
export const BOAPost = () => import('../../components/ByOfficialAppointment/Post/BOAPost.vue' /* webpackChunkName: "components/b-o-a-post" */).then(c => wrapFunctional(c.default || c))
export const ArticleHero = () => import('../../components/Content/Article/ArticleHero.vue' /* webpackChunkName: "components/article-hero" */).then(c => wrapFunctional(c.default || c))
export const ArticlePagination = () => import('../../components/Content/Article/ArticlePagination.vue' /* webpackChunkName: "components/article-pagination" */).then(c => wrapFunctional(c.default || c))
export const ArticleTitle = () => import('../../components/Content/Article/ArticleTitle.vue' /* webpackChunkName: "components/article-title" */).then(c => wrapFunctional(c.default || c))
export const Excerpt = () => import('../../components/Content/Article/Excerpt.vue' /* webpackChunkName: "components/excerpt" */).then(c => wrapFunctional(c.default || c))
export const PostMeta = () => import('../../components/Content/Article/PostMeta.vue' /* webpackChunkName: "components/post-meta" */).then(c => wrapFunctional(c.default || c))
export const RelatedArticle = () => import('../../components/Content/Article/RelatedArticle.vue' /* webpackChunkName: "components/related-article" */).then(c => wrapFunctional(c.default || c))
export const RelatedArticles = () => import('../../components/Content/Article/RelatedArticles.vue' /* webpackChunkName: "components/related-articles" */).then(c => wrapFunctional(c.default || c))
export const Tags = () => import('../../components/Content/Article/Tags.vue' /* webpackChunkName: "components/tags" */).then(c => wrapFunctional(c.default || c))
export const Accordion = () => import('../../components/ContentBlocks/Accordion/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const AccordionSection = () => import('../../components/ContentBlocks/Accordion/AccordionSection.vue' /* webpackChunkName: "components/accordion-section" */).then(c => wrapFunctional(c.default || c))
export const ImageGallery = () => import('../../components/ContentBlocks/MediaBlocks/ImageGallery.vue' /* webpackChunkName: "components/image-gallery" */).then(c => wrapFunctional(c.default || c))
export const MediaSlider = () => import('../../components/ContentBlocks/MediaBlocks/MediaSlider.vue' /* webpackChunkName: "components/media-slider" */).then(c => wrapFunctional(c.default || c))
export const PartnerSlider = () => import('../../components/ContentBlocks/MediaBlocks/PartnerSlider.vue' /* webpackChunkName: "components/partner-slider" */).then(c => wrapFunctional(c.default || c))
export const VideoSlider = () => import('../../components/ContentBlocks/MediaBlocks/VideoSlider.vue' /* webpackChunkName: "components/video-slider" */).then(c => wrapFunctional(c.default || c))
export const PostsSlider = () => import('../../components/ContentBlocks/PostsSlider/PostsSlider.vue' /* webpackChunkName: "components/posts-slider" */).then(c => wrapFunctional(c.default || c))
export const ShowcaseContentSlider = () => import('../../components/ContentBlocks/ShowcaseSlider/ShowcaseContentSlider.vue' /* webpackChunkName: "components/showcase-content-slider" */).then(c => wrapFunctional(c.default || c))
export const ShowcaseSlider = () => import('../../components/ContentBlocks/ShowcaseSlider/ShowcaseSlider.vue' /* webpackChunkName: "components/showcase-slider" */).then(c => wrapFunctional(c.default || c))
export const ShowcaseSliderPagination = () => import('../../components/ContentBlocks/ShowcaseSlider/ShowcaseSliderPagination.vue' /* webpackChunkName: "components/showcase-slider-pagination" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/ContentBlocks/Tabs/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const ContentAccordion = () => import('../../components/ContentBlocks/TwoColumn/ContentAccordion.vue' /* webpackChunkName: "components/content-accordion" */).then(c => wrapFunctional(c.default || c))
export const ContentHero = () => import('../../components/ContentBlocks/TwoColumn/ContentHero.vue' /* webpackChunkName: "components/content-hero" */).then(c => wrapFunctional(c.default || c))
export const MediaContent = () => import('../../components/ContentBlocks/TwoColumn/MediaContent.vue' /* webpackChunkName: "components/media-content" */).then(c => wrapFunctional(c.default || c))
export const MediaContentContent = () => import('../../components/ContentBlocks/TwoColumn/MediaContentContent.vue' /* webpackChunkName: "components/media-content-content" */).then(c => wrapFunctional(c.default || c))
export const MediaContentMedia = () => import('../../components/ContentBlocks/TwoColumn/MediaContentMedia.vue' /* webpackChunkName: "components/media-content-media" */).then(c => wrapFunctional(c.default || c))
export const ConditionalDFP = () => import('../../components/DFP/Aggregator/ConditionalDFP.vue' /* webpackChunkName: "components/conditional-d-f-p" */).then(c => wrapFunctional(c.default || c))
export const DFPNewsCard = () => import('../../components/DFP/Aggregator/DFPNewsCard.vue' /* webpackChunkName: "components/d-f-p-news-card" */).then(c => wrapFunctional(c.default || c))
export const StandardDFP = () => import('../../components/DFP/Standard/StandardDFP.vue' /* webpackChunkName: "components/standard-d-f-p" */).then(c => wrapFunctional(c.default || c))
export const FooterBoa = () => import('../../components/Footer/BOA/FooterBoa.vue' /* webpackChunkName: "components/footer-boa" */).then(c => wrapFunctional(c.default || c))
export const FooterMenu = () => import('../../components/Footer/Menu/FooterMenu.vue' /* webpackChunkName: "components/footer-menu" */).then(c => wrapFunctional(c.default || c))
export const FooterPartner = () => import('../../components/Footer/Partners/FooterPartner.vue' /* webpackChunkName: "components/footer-partner" */).then(c => wrapFunctional(c.default || c))
export const FooterPartners = () => import('../../components/Footer/Partners/FooterPartners.vue' /* webpackChunkName: "components/footer-partners" */).then(c => wrapFunctional(c.default || c))
export const FixtureCard = () => import('../../components/Fixtures/Card/FixtureCard.vue' /* webpackChunkName: "components/fixture-card" */).then(c => wrapFunctional(c.default || c))
export const FixturesFilter = () => import('../../components/Fixtures/Filter/FixturesFilter.vue' /* webpackChunkName: "components/fixtures-filter" */).then(c => wrapFunctional(c.default || c))
export const FixtureLoadMore = () => import('../../components/Fixtures/LoadMore/FixtureLoadMore.vue' /* webpackChunkName: "components/fixture-load-more" */).then(c => wrapFunctional(c.default || c))
export const HomeLeaderboard = () => import('../../components/Home/Blocks/HomeLeaderboard.vue' /* webpackChunkName: "components/home-leaderboard" */).then(c => wrapFunctional(c.default || c))
export const HomeShop = () => import('../../components/Home/Blocks/HomeShop.vue' /* webpackChunkName: "components/home-shop" */).then(c => wrapFunctional(c.default || c))
export const MatchComponents = () => import('../../components/Home/Blocks/MatchComponents.vue' /* webpackChunkName: "components/match-components" */).then(c => wrapFunctional(c.default || c))
export const HeroContent = () => import('../../components/Home/HomeRedTV/HeroContent.vue' /* webpackChunkName: "components/hero-content" */).then(c => wrapFunctional(c.default || c))
export const HomeRedTv = () => import('../../components/Home/HomeRedTV/HomeRedTv.vue' /* webpackChunkName: "components/home-red-tv" */).then(c => wrapFunctional(c.default || c))
export const RedTvSlider = () => import('../../components/Home/HomeRedTV/RedTvSlider.vue' /* webpackChunkName: "components/red-tv-slider" */).then(c => wrapFunctional(c.default || c))
export const VideoCard = () => import('../../components/Home/HomeRedTV/VideoCard.vue' /* webpackChunkName: "components/video-card" */).then(c => wrapFunctional(c.default || c))
export const HeroSlide = () => import('../../components/Home/MainCarousel/HeroSlide.vue' /* webpackChunkName: "components/hero-slide" */).then(c => wrapFunctional(c.default || c))
export const HeroSlideContent = () => import('../../components/Home/MainCarousel/HeroSlideContent.vue' /* webpackChunkName: "components/hero-slide-content" */).then(c => wrapFunctional(c.default || c))
export const HomeHero = () => import('../../components/Home/MainCarousel/HomeHero.vue' /* webpackChunkName: "components/home-hero" */).then(c => wrapFunctional(c.default || c))
export const HomeHeroPagination = () => import('../../components/Home/MainCarousel/HomeHeroPagination.vue' /* webpackChunkName: "components/home-hero-pagination" */).then(c => wrapFunctional(c.default || c))
export const HomeHeroTab = () => import('../../components/Home/MainCarousel/HomeHeroTab.vue' /* webpackChunkName: "components/home-hero-tab" */).then(c => wrapFunctional(c.default || c))
export const MatchHero = () => import('../../components/MatchCentre/Hero/MatchHero.vue' /* webpackChunkName: "components/match-hero" */).then(c => wrapFunctional(c.default || c))
export const MatchCentreFixture = () => import('../../components/MatchCentre/Info/MatchCentreFixture.vue' /* webpackChunkName: "components/match-centre-fixture" */).then(c => wrapFunctional(c.default || c))
export const MatchCentreInfo = () => import('../../components/MatchCentre/Info/MatchCentreInfo.vue' /* webpackChunkName: "components/match-centre-info" */).then(c => wrapFunctional(c.default || c))
export const MatchCentreLive = () => import('../../components/MatchCentre/Info/MatchCentreLive.vue' /* webpackChunkName: "components/match-centre-live" */).then(c => wrapFunctional(c.default || c))
export const MatchCentreResult = () => import('../../components/MatchCentre/Info/MatchCentreResult.vue' /* webpackChunkName: "components/match-centre-result" */).then(c => wrapFunctional(c.default || c))
export const MatchFinalScore = () => import('../../components/MatchCentre/Sidebar/MatchFinalScore.vue' /* webpackChunkName: "components/match-final-score" */).then(c => wrapFunctional(c.default || c))
export const MatchLeaguePosition = () => import('../../components/MatchCentre/Sidebar/MatchLeaguePosition.vue' /* webpackChunkName: "components/match-league-position" */).then(c => wrapFunctional(c.default || c))
export const MatchPredictScore = () => import('../../components/MatchCentre/Sidebar/MatchPredictScore.vue' /* webpackChunkName: "components/match-predict-score" */).then(c => wrapFunctional(c.default || c))
export const MatchPredictScoreResult = () => import('../../components/MatchCentre/Sidebar/MatchPredictScoreResult.vue' /* webpackChunkName: "components/match-predict-score-result" */).then(c => wrapFunctional(c.default || c))
export const MatchPredictScoreShare = () => import('../../components/MatchCentre/Sidebar/MatchPredictScoreShare.vue' /* webpackChunkName: "components/match-predict-score-share" */).then(c => wrapFunctional(c.default || c))
export const MatchPreviousMeetings = () => import('../../components/MatchCentre/Sidebar/MatchPreviousMeetings.vue' /* webpackChunkName: "components/match-previous-meetings" */).then(c => wrapFunctional(c.default || c))
export const MatchShareModal = () => import('../../components/MatchCentre/Sidebar/MatchShareModal.vue' /* webpackChunkName: "components/match-share-modal" */).then(c => wrapFunctional(c.default || c))
export const MatchCentreTeamStatsGroup = () => import('../../components/MatchCentre/Stats/MatchCentreTeamStatsGroup.vue' /* webpackChunkName: "components/match-centre-team-stats-group" */).then(c => wrapFunctional(c.default || c))
export const MatchCentreTabEditorial = () => import('../../components/MatchCentre/Tabs/MatchCentreTabEditorial.vue' /* webpackChunkName: "components/match-centre-tab-editorial" */).then(c => wrapFunctional(c.default || c))
export const MatchCentreTabLineups = () => import('../../components/MatchCentre/Tabs/MatchCentreTabLineups.vue' /* webpackChunkName: "components/match-centre-tab-lineups" */).then(c => wrapFunctional(c.default || c))
export const MatchCentreTabLive = () => import('../../components/MatchCentre/Tabs/MatchCentreTabLive.vue' /* webpackChunkName: "components/match-centre-tab-live" */).then(c => wrapFunctional(c.default || c))
export const MatchCentreTabMedia = () => import('../../components/MatchCentre/Tabs/MatchCentreTabMedia.vue' /* webpackChunkName: "components/match-centre-tab-media" */).then(c => wrapFunctional(c.default || c))
export const MatchCentreTabStats = () => import('../../components/MatchCentre/Tabs/MatchCentreTabStats.vue' /* webpackChunkName: "components/match-centre-tab-stats" */).then(c => wrapFunctional(c.default || c))
export const FixtureSmall = () => import('../../components/MatchComponents/FixturesSmall/FixtureSmall.vue' /* webpackChunkName: "components/fixture-small" */).then(c => wrapFunctional(c.default || c))
export const FixturesSmall = () => import('../../components/MatchComponents/FixturesSmall/FixturesSmall.vue' /* webpackChunkName: "components/fixtures-small" */).then(c => wrapFunctional(c.default || c))
export const LineupSelector = () => import('../../components/MatchComponents/LineUpSelector/LineupSelector.vue' /* webpackChunkName: "components/lineup-selector" */).then(c => wrapFunctional(c.default || c))
export const LineupSelectorPlayer = () => import('../../components/MatchComponents/LineUpSelector/LineupSelectorPlayer.vue' /* webpackChunkName: "components/lineup-selector-player" */).then(c => wrapFunctional(c.default || c))
export const LineupSelectorPlayers = () => import('../../components/MatchComponents/LineUpSelector/LineupSelectorPlayers.vue' /* webpackChunkName: "components/lineup-selector-players" */).then(c => wrapFunctional(c.default || c))
export const LineupSelectorShare = () => import('../../components/MatchComponents/LineUpSelector/LineupSelectorShare.vue' /* webpackChunkName: "components/lineup-selector-share" */).then(c => wrapFunctional(c.default || c))
export const PredictorShare = () => import('../../components/MatchComponents/LineUpSelector/PredictorShare.vue' /* webpackChunkName: "components/predictor-share" */).then(c => wrapFunctional(c.default || c))
export const Lineup = () => import('../../components/MatchComponents/Lineups/Lineup.vue' /* webpackChunkName: "components/lineup" */).then(c => wrapFunctional(c.default || c))
export const LineupPlayer = () => import('../../components/MatchComponents/Lineups/LineupPlayer.vue' /* webpackChunkName: "components/lineup-player" */).then(c => wrapFunctional(c.default || c))
export const LineupPlayerStats = () => import('../../components/MatchComponents/Lineups/LineupPlayerStats.vue' /* webpackChunkName: "components/lineup-player-stats" */).then(c => wrapFunctional(c.default || c))
export const Lineups = () => import('../../components/MatchComponents/Lineups/Lineups.vue' /* webpackChunkName: "components/lineups" */).then(c => wrapFunctional(c.default || c))
export const LiveFormation = () => import('../../components/MatchComponents/Lineups/LiveFormation.vue' /* webpackChunkName: "components/live-formation" */).then(c => wrapFunctional(c.default || c))
export const MatchLiveFeedBlock = () => import('../../components/MatchComponents/LiveFeed/MatchLiveFeedBlock.vue' /* webpackChunkName: "components/match-live-feed-block" */).then(c => wrapFunctional(c.default || c))
export const MatchLiveFeedEventInfo = () => import('../../components/MatchComponents/LiveFeed/MatchLiveFeedEventInfo.vue' /* webpackChunkName: "components/match-live-feed-event-info" */).then(c => wrapFunctional(c.default || c))
export const MatchLiveFeedHeader = () => import('../../components/MatchComponents/LiveFeed/MatchLiveFeedHeader.vue' /* webpackChunkName: "components/match-live-feed-header" */).then(c => wrapFunctional(c.default || c))
export const MatchLiveFeedSubstitution = () => import('../../components/MatchComponents/LiveFeed/MatchLiveFeedSubstitution.vue' /* webpackChunkName: "components/match-live-feed-substitution" */).then(c => wrapFunctional(c.default || c))
export const ShotEventChart = () => import('../../components/MatchComponents/LiveFeed/ShotEventChart.vue' /* webpackChunkName: "components/shot-event-chart" */).then(c => wrapFunctional(c.default || c))
export const NextMatchEvent = () => import('../../components/MatchComponents/NextMatch/NextMatchEvent.vue' /* webpackChunkName: "components/next-match-event" */).then(c => wrapFunctional(c.default || c))
export const NextMatchFeed = () => import('../../components/MatchComponents/NextMatch/NextMatchFeed.vue' /* webpackChunkName: "components/next-match-feed" */).then(c => wrapFunctional(c.default || c))
export const NextMatchFeedEvent = () => import('../../components/MatchComponents/NextMatch/NextMatchFeedEvent.vue' /* webpackChunkName: "components/next-match-feed-event" */).then(c => wrapFunctional(c.default || c))
export const NextMatchFixture = () => import('../../components/MatchComponents/NextMatch/NextMatchFixture.vue' /* webpackChunkName: "components/next-match-fixture" */).then(c => wrapFunctional(c.default || c))
export const NextMatchLive = () => import('../../components/MatchComponents/NextMatch/NextMatchLive.vue' /* webpackChunkName: "components/next-match-live" */).then(c => wrapFunctional(c.default || c))
export const NextMatchResult = () => import('../../components/MatchComponents/NextMatch/NextMatchResult.vue' /* webpackChunkName: "components/next-match-result" */).then(c => wrapFunctional(c.default || c))
export const PlayerComparison = () => import('../../components/MatchComponents/PlayerComparison/PlayerComparison.vue' /* webpackChunkName: "components/player-comparison" */).then(c => wrapFunctional(c.default || c))
export const PlayerComparisonPlayer = () => import('../../components/MatchComponents/PlayerComparison/PlayerComparisonPlayer.vue' /* webpackChunkName: "components/player-comparison-player" */).then(c => wrapFunctional(c.default || c))
export const PlayerComparisonStats = () => import('../../components/MatchComponents/PlayerComparison/PlayerComparisonStats.vue' /* webpackChunkName: "components/player-comparison-stats" */).then(c => wrapFunctional(c.default || c))
export const PlayerStatsLastMatch = () => import('../../components/Player/Stats/PlayerStatsLastMatch.vue' /* webpackChunkName: "components/player-stats-last-match" */).then(c => wrapFunctional(c.default || c))
export const PlayerStatsMatchSummary = () => import('../../components/Player/Stats/PlayerStatsMatchSummary.vue' /* webpackChunkName: "components/player-stats-match-summary" */).then(c => wrapFunctional(c.default || c))
export const PlayerStatsSeason = () => import('../../components/Player/Stats/PlayerStatsSeason.vue' /* webpackChunkName: "components/player-stats-season" */).then(c => wrapFunctional(c.default || c))
export const SearchInput = () => import('../../components/Search/Forms/SearchInput.vue' /* webpackChunkName: "components/search-input" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../components/Search/Layouts/SearchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const SearchOverlay = () => import('../../components/Search/Layouts/SearchOverlay.vue' /* webpackChunkName: "components/search-overlay" */).then(c => wrapFunctional(c.default || c))
export const StaffHeader = () => import('../../components/Staff/Member/StaffHeader.vue' /* webpackChunkName: "components/staff-header" */).then(c => wrapFunctional(c.default || c))
export const StaffInfo = () => import('../../components/Staff/Member/StaffInfo.vue' /* webpackChunkName: "components/staff-info" */).then(c => wrapFunctional(c.default || c))
export const StaffMember = () => import('../../components/Staff/Member/StaffMember.vue' /* webpackChunkName: "components/staff-member" */).then(c => wrapFunctional(c.default || c))
export const PlayersBlock = () => import('../../components/Stats/Blocks/PlayersBlock.vue' /* webpackChunkName: "components/players-block" */).then(c => wrapFunctional(c.default || c))
export const TeamsBlock = () => import('../../components/Stats/Blocks/TeamsBlock.vue' /* webpackChunkName: "components/teams-block" */).then(c => wrapFunctional(c.default || c))
export const DoughnutChart = () => import('../../components/Stats/Charts/DoughnutChart.vue' /* webpackChunkName: "components/doughnut-chart" */).then(c => wrapFunctional(c.default || c))
export const Gauge = () => import('../../components/Stats/Charts/Gauge.vue' /* webpackChunkName: "components/gauge" */).then(c => wrapFunctional(c.default || c))
export const PlayerPolarChart = () => import('../../components/Stats/Charts/PlayerPolarChart.vue' /* webpackChunkName: "components/player-polar-chart" */).then(c => wrapFunctional(c.default || c))
export const PolarChart = () => import('../../components/Stats/Charts/PolarChart.vue' /* webpackChunkName: "components/polar-chart" */).then(c => wrapFunctional(c.default || c))
export const RadarChart = () => import('../../components/Stats/Charts/RadarChart.vue' /* webpackChunkName: "components/radar-chart" */).then(c => wrapFunctional(c.default || c))
export const StatBar = () => import('../../components/Stats/Charts/StatBar.vue' /* webpackChunkName: "components/stat-bar" */).then(c => wrapFunctional(c.default || c))
export const StatsLegend = () => import('../../components/Stats/Charts/StatsLegend.vue' /* webpackChunkName: "components/stats-legend" */).then(c => wrapFunctional(c.default || c))
export const StatsFilter = () => import('../../components/Stats/Filter/StatsFilter.vue' /* webpackChunkName: "components/stats-filter" */).then(c => wrapFunctional(c.default || c))
export const BOAAffiliateLevel = () => import('../../components/ByOfficialAppointment/Index/BOAIndex/BOAAffiliateLevel.vue' /* webpackChunkName: "components/b-o-a-affiliate-level" */).then(c => wrapFunctional(c.default || c))
export const BOAIndex = () => import('../../components/ByOfficialAppointment/Index/BOAIndex/BOAIndex.vue' /* webpackChunkName: "components/b-o-a-index" */).then(c => wrapFunctional(c.default || c))
export const BOAOffers = () => import('../../components/ByOfficialAppointment/Index/BOAOffers/BOAOffers.vue' /* webpackChunkName: "components/b-o-a-offers" */).then(c => wrapFunctional(c.default || c))
export const BOAFilter = () => import('../../components/ByOfficialAppointment/Index/Filter/BOAFilter.vue' /* webpackChunkName: "components/b-o-a-filter" */).then(c => wrapFunctional(c.default || c))
export const FooterBoaCard = () => import('../../components/Footer/BOA/Cards/FooterBoaCard.vue' /* webpackChunkName: "components/footer-boa-card" */).then(c => wrapFunctional(c.default || c))
export const OfferCard = () => import('../../components/Footer/BOA/Cards/OfferCard.vue' /* webpackChunkName: "components/offer-card" */).then(c => wrapFunctional(c.default || c))
export const EventButtons = () => import('../../components/Fixtures/Card/Buttons/EventButtons.vue' /* webpackChunkName: "components/event-buttons" */).then(c => wrapFunctional(c.default || c))
export const FixtureButtons = () => import('../../components/Fixtures/Card/Buttons/FixtureButtons.vue' /* webpackChunkName: "components/fixture-buttons" */).then(c => wrapFunctional(c.default || c))
export const FixtureScores = () => import('../../components/Fixtures/Card/Scores/FixtureScores.vue' /* webpackChunkName: "components/fixture-scores" */).then(c => wrapFunctional(c.default || c))
export const FixtureLive = () => import('../../components/Fixtures/Card/State/FixtureLive.vue' /* webpackChunkName: "components/fixture-live" */).then(c => wrapFunctional(c.default || c))
export const FixturePreLive = () => import('../../components/Fixtures/Card/State/FixturePreLive.vue' /* webpackChunkName: "components/fixture-pre-live" */).then(c => wrapFunctional(c.default || c))
export const AberDNA = () => import('../../components/Header/Sections/AberDNA/AberDNA.vue' /* webpackChunkName: "components/aber-d-n-a" */).then(c => wrapFunctional(c.default || c))
export const AccountMenu = () => import('../../components/Header/Sections/AberDNA/AccountMenu.vue' /* webpackChunkName: "components/account-menu" */).then(c => wrapFunctional(c.default || c))
export const AccountMenuMobile = () => import('../../components/Header/Sections/AberDNA/AccountMenuMobile.vue' /* webpackChunkName: "components/account-menu-mobile" */).then(c => wrapFunctional(c.default || c))
export const MenuItem = () => import('../../components/Header/Sections/Mobile/MenuItem.vue' /* webpackChunkName: "components/menu-item" */).then(c => wrapFunctional(c.default || c))
export const MenuPage = () => import('../../components/Header/Sections/Mobile/MenuPage.vue' /* webpackChunkName: "components/menu-page" */).then(c => wrapFunctional(c.default || c))
export const MobileMenu = () => import('../../components/Header/Sections/Mobile/MobileMenu.vue' /* webpackChunkName: "components/mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const MobileNav = () => import('../../components/Header/Sections/Mobile/MobileNav.vue' /* webpackChunkName: "components/mobile-nav" */).then(c => wrapFunctional(c.default || c))
export const DesktopNav = () => import('../../components/Header/Sections/Navigation/DesktopNav.vue' /* webpackChunkName: "components/desktop-nav" */).then(c => wrapFunctional(c.default || c))
export const MenuList = () => import('../../components/Header/Sections/Navigation/MenuList.vue' /* webpackChunkName: "components/menu-list" */).then(c => wrapFunctional(c.default || c))
export const PartnerList = () => import('../../components/Header/Sections/Navigation/PartnerList.vue' /* webpackChunkName: "components/partner-list" */).then(c => wrapFunctional(c.default || c))
export const SiteSearch = () => import('../../components/Header/Sections/Search/SiteSearch.vue' /* webpackChunkName: "components/site-search" */).then(c => wrapFunctional(c.default || c))
export const NavigationSubmenu = () => import('../../components/Header/Sections/Submenu/NavigationSubmenu.vue' /* webpackChunkName: "components/navigation-submenu" */).then(c => wrapFunctional(c.default || c))
export const Submenu = () => import('../../components/Header/Sections/Submenu/Submenu.vue' /* webpackChunkName: "components/submenu" */).then(c => wrapFunctional(c.default || c))
export const MatchForm = () => import('../../components/MatchCentre/Sidebar/MatchForm/MatchForm.vue' /* webpackChunkName: "components/match-form" */).then(c => wrapFunctional(c.default || c))
export const MatchFormTeam = () => import('../../components/MatchCentre/Sidebar/MatchForm/MatchFormTeam.vue' /* webpackChunkName: "components/match-form-team" */).then(c => wrapFunctional(c.default || c))
export const MatchFormTooltip = () => import('../../components/MatchCentre/Sidebar/MatchForm/MatchFormTooltip.vue' /* webpackChunkName: "components/match-form-tooltip" */).then(c => wrapFunctional(c.default || c))
export const MatchSidebarStatGroup = () => import('../../components/MatchCentre/Sidebar/Stats/MatchSidebarStatGroup.vue' /* webpackChunkName: "components/match-sidebar-stat-group" */).then(c => wrapFunctional(c.default || c))
export const MatchSidebarStats = () => import('../../components/MatchCentre/Sidebar/Stats/MatchSidebarStats.vue' /* webpackChunkName: "components/match-sidebar-stats" */).then(c => wrapFunctional(c.default || c))
export const FormationPlayer = () => import('../../components/MatchComponents/Lineups/Formation/FormationPlayer.vue' /* webpackChunkName: "components/formation-player" */).then(c => wrapFunctional(c.default || c))
export const FormationPlayerIcons = () => import('../../components/MatchComponents/Lineups/Formation/FormationPlayerIcons.vue' /* webpackChunkName: "components/formation-player-icons" */).then(c => wrapFunctional(c.default || c))
export const FormationPlayerTooltip = () => import('../../components/MatchComponents/Lineups/Formation/FormationPlayerTooltip.vue' /* webpackChunkName: "components/formation-player-tooltip" */).then(c => wrapFunctional(c.default || c))
export const FormationTeam = () => import('../../components/MatchComponents/Lineups/Formation/FormationTeam.vue' /* webpackChunkName: "components/formation-team" */).then(c => wrapFunctional(c.default || c))
export const PlayerLastMatchInfo = () => import('../../components/Player/Stats/Groups/PlayerLastMatchInfo.vue' /* webpackChunkName: "components/player-last-match-info" */).then(c => wrapFunctional(c.default || c))
export const PlayerStatsAttacking = () => import('../../components/Player/Stats/Groups/PlayerStatsAttacking.vue' /* webpackChunkName: "components/player-stats-attacking" */).then(c => wrapFunctional(c.default || c))
export const PlayerStatsDefending = () => import('../../components/Player/Stats/Groups/PlayerStatsDefending.vue' /* webpackChunkName: "components/player-stats-defending" */).then(c => wrapFunctional(c.default || c))
export const PlayerStatsGeneral = () => import('../../components/Player/Stats/Groups/PlayerStatsGeneral.vue' /* webpackChunkName: "components/player-stats-general" */).then(c => wrapFunctional(c.default || c))
export const PlayerStatsGoalkeeping = () => import('../../components/Player/Stats/Groups/PlayerStatsGoalkeeping.vue' /* webpackChunkName: "components/player-stats-goalkeeping" */).then(c => wrapFunctional(c.default || c))
export const PlayerStatsGoals = () => import('../../components/Player/Stats/Groups/PlayerStatsGoals.vue' /* webpackChunkName: "components/player-stats-goals" */).then(c => wrapFunctional(c.default || c))
export const PlayerStatsPassing = () => import('../../components/Player/Stats/Groups/PlayerStatsPassing.vue' /* webpackChunkName: "components/player-stats-passing" */).then(c => wrapFunctional(c.default || c))
export const PlayerStatsPenalties = () => import('../../components/Player/Stats/Groups/PlayerStatsPenalties.vue' /* webpackChunkName: "components/player-stats-penalties" */).then(c => wrapFunctional(c.default || c))
export const PlayerStatsShooting = () => import('../../components/Player/Stats/Groups/PlayerStatsShooting.vue' /* webpackChunkName: "components/player-stats-shooting" */).then(c => wrapFunctional(c.default || c))
export const SearchArticle = () => import('../../components/Search/Information/FullSearch/SearchArticle.vue' /* webpackChunkName: "components/search-article" */).then(c => wrapFunctional(c.default || c))
export const SearchFull = () => import('../../components/Search/Information/FullSearch/SearchFull.vue' /* webpackChunkName: "components/search-full" */).then(c => wrapFunctional(c.default || c))
export const SearchInitial = () => import('../../components/Search/Information/InitialSearch/SearchInitial.vue' /* webpackChunkName: "components/search-initial" */).then(c => wrapFunctional(c.default || c))
export const SearchSuggestions = () => import('../../components/Search/Information/Suggestions/SearchSuggestions.vue' /* webpackChunkName: "components/search-suggestions" */).then(c => wrapFunctional(c.default || c))
export const SearchType = () => import('../../components/Search/Information/Suggestions/SearchType.vue' /* webpackChunkName: "components/search-type" */).then(c => wrapFunctional(c.default || c))
export const DoughnutChartVsLeagueAverage = () => import('../../components/Stats/Charts/Custom/DoughnutChartVsLeagueAverage.vue' /* webpackChunkName: "components/doughnut-chart-vs-league-average" */).then(c => wrapFunctional(c.default || c))
export const GoalPositions = () => import('../../components/Stats/Charts/Custom/GoalPositions.vue' /* webpackChunkName: "components/goal-positions" */).then(c => wrapFunctional(c.default || c))
export const LeaguePositions = () => import('../../components/Stats/Charts/Custom/LeaguePositions.vue' /* webpackChunkName: "components/league-positions" */).then(c => wrapFunctional(c.default || c))
export const PossessionTimeline = () => import('../../components/Stats/Charts/Custom/PossessionTimeline.vue' /* webpackChunkName: "components/possession-timeline" */).then(c => wrapFunctional(c.default || c))
export const SegmentedGoalPositions = () => import('../../components/Stats/Charts/Custom/SegmentedGoalPositions.vue' /* webpackChunkName: "components/segmented-goal-positions" */).then(c => wrapFunctional(c.default || c))
export const ShotPlacementChart = () => import('../../components/Stats/Charts/Custom/ShotPlacementChart.vue' /* webpackChunkName: "components/shot-placement-chart" */).then(c => wrapFunctional(c.default || c))
export const ShotPlacementIcon = () => import('../../components/Stats/Charts/Custom/ShotPlacementIcon.vue' /* webpackChunkName: "components/shot-placement-icon" */).then(c => wrapFunctional(c.default || c))
export const BOACard = () => import('../../components/ByOfficialAppointment/Index/BOAIndex/Card/BOACard.vue' /* webpackChunkName: "components/b-o-a-card" */).then(c => wrapFunctional(c.default || c))
export const BOAOfferCard = () => import('../../components/ByOfficialAppointment/Index/BOAOffers/Card/BOAOfferCard.vue' /* webpackChunkName: "components/b-o-a-offer-card" */).then(c => wrapFunctional(c.default || c))
export const BOAOffersFilter = () => import('../../components/ByOfficialAppointment/Index/BOAOffers/Filter/BOAOffersFilter.vue' /* webpackChunkName: "components/b-o-a-offers-filter" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
